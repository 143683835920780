import { railsBackend } from '@/lib/axios'

export default {
  index: (params: any) => railsBackend.get('/api/v1/coach/pupils', { params: params }),
  create: (params: any) => railsBackend.post('/api/v1/coach/pupils/invite', params),
  show: (params: any) => railsBackend.get(`/api/v1/coach/pupils/${params.id}`),
  shortList: () => railsBackend.get('/api/v1/coach/pupils/short_index'),
  generateResetLink: (pupilID: number) =>
    railsBackend.post(`/api/v1/coach/pupils/${pupilID}/generate_reset_link`)
}
