import { railsBackend } from '@/lib/axios'
import { objectToFormData } from 'object-to-formdata'

const progressConfig = function (percentCompleted) {
  const onUploadProgress = function (progressEvent) {
    percentCompleted.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  }

  return { onUploadProgress }
}

export default {
  index: (params) => railsBackend.get('/api/v1/coach/materials', { params: params }),
  show: (id) => railsBackend.get(`/api/v1/coach/materials/${id}`),
  create: (params, progress) => railsBackend.post('/api/v1/coach/materials', objectToFormData(params), progressConfig(progress)),
  update: (params) => railsBackend.put(`/api/v1/coach/materials/${params.material.id}`, objectToFormData(params)),
  destroy: (params) => railsBackend.delete(`/api/v1/coach/materials/${params.material.id}`)
}
