import { railsBackend } from '@/lib/axios'
import { objectToFormData } from 'object-to-formdata'

export default {
  getTrainingPrograms: (params) => railsBackend.get('/api/v1/coach/training_programs', { params }),
  getTrainingProgram: (params) => railsBackend.get(`/api/v1/coach/training_programs/${params.id}`),
  createTrainingProgram: (params) => railsBackend.post('/api/v1/coach/training_programs', objectToFormData(params)),
  updateTrainingProgram: (params) => railsBackend.put(`/api/v1/coach/training_programs/${params.training_program.id}`, objectToFormData(params)),
  deleteTrainingProgram: (id) => railsBackend.delete(`/api/v1/coach/training_programs/${id}`),
  getActualTrainingProgram: (params) => railsBackend.get(`/api/v1/users/${params.user_id}/shared_actual_training_program`),
  savePupilsList: (params) => railsBackend.post(`/api/v1/coach/training_programs/${params.id}/assign_users`, params)
}
