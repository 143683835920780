














import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import { IFileObject } from '@/interfaces/IFileObject'

@Component({
  methods: {
    ...mapActions({
      create: 'materialsStore/create'
    })
  },
  computed: {
    ...mapGetters({
      uploadsQuantity: 'uploadsQuantity'
    })
  }
})

export default class FileUpload extends Vue {
  @Prop(Object) item!: IFileObject
  @Prop() private index?: Number;
  @Prop() private sectionIndex?: Number
  @Ref('videoInput') videoInput!: HTMLInputElement

  progress = { progress: 0 }
  fileTitle = ''
  create!: (params: Object) => any

  catchVideo (e: any) {
    const file = e.target.files[0]
    const title = file.name.split('.').slice(0, -1).join('.')

    this.fileTitle = title

    const params = {
      params: {
        file, title
      },
      progress: this.progress
    }

    this.create(params).then((response: any) => {
      this.item.material_id = response.id

      this.videoInput.value = ''
    })
  }
}
