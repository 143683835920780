<template lang="pug">
  .embed-responsive.embed-responsive-16by9
    video(preload="metadata" controls :width="width" :height="height" :poster="poster" :src="url")
</template>

<script>
export default {
  props: {
    url: {
      required: true,
      type: String,
      default: () => ''
    },
    poster: {
      required: false,
      type: String,
      default: () => ''
    },
    width: {
      required: false,
      type: Number,
      default: 400
    },
    height: {
      required: false,
      type: Number,
      default: 320
    }
  }
}
</script>
