import axios from 'axios'

const token = localStorage.getItem('token')
const railsBackend = axios.create({})
const diaryBackend = axios.create({})

railsBackend.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
diaryBackend.defaults.baseURL = process.env.VUE_APP_DIARY_URL

if (token) {
  railsBackend.defaults.headers.common.Authorization = token
}

railsBackend.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const statusCode = error.response.status

  switch (statusCode) {
    case 403:
      window.location.href = '/'
      break
    case 401:
      if (window.location.pathname !== '/users/sign_in' &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/users/accept_invitation' &&
      window.location.pathname !== '/users/send_reset_password_instructions' &&
      window.location.pathname !== '/users/reset_password' &&
      window.location.pathname !== '/users/registration') {
        window.location.href = '/users/sign_in'
      }
      break
  }

  return Promise.reject(error)
})

export {
  railsBackend,
  diaryBackend
}
