interface IRoute {
  params: {
    [key: string]: IParam
  }
}

interface IParam {
  id: number
}

class TrainingProgramsTypeDetector {
  _type = ''
  _entity = {}
  _route: IRoute

  constructor (type: any, route: IRoute) {
    this._type = type
    this._route = route
  }

  get entity () {
    return this._entity
  }

  calcEntity () {
    const route = this._route

    switch (this._type) {
      case 'training_program':
        this._entity = {
          editPath: 'edit_pupil_training_program',
          returnPath (programId: any) {
            return {
              name: 'pupil_training_program_show', params: { id: route.params.id, program_id: programId }
            }
          },
          trainingProgramEntity: {
            pupil_id: this._route.params.id,
            title: '',
            sections_attributes: [],
            comment: '',
            actual: true
          },
          entityName: 'training_program'
        }
        break
      case 'shared_training_program':
        this._entity = {
          editPath: 'edit_shared_training_program',
          returnPath (programId: any) {
            return {
              name: 'shared_training_programs_show',
              params: { id: programId }
            }
          },
          trainingProgramEntity: {
            title: '',
            sections_attributes: [],
            comment: '',
            actual: true
          },
          entityName: 'shared_training_program'
        }
        break
    }
  }

  get entityName () {
    return this._type
  }

  mappedCreateAction () {
    switch (this._type) {
      case 'training_program':
        return 'trainingProgramsStore/create'
      case 'shared_training_program':
        return 'sharedTrainingPrograms/postCreate'
    }
  }

  mappedUpdateAction () {
    switch (this._type) {
      case 'training_program':
        return 'trainingProgramsStore/update'
      case 'shared_training_program':
        return 'sharedTrainingPrograms/putUpdate'
    }
  }
}

export default TrainingProgramsTypeDetector
