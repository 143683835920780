import { railsBackend } from '@/lib/axios'
import { objectToFormData } from 'object-to-formdata'

export default {
  getUser: () => railsBackend.get('/api/v1/users/me'),
  update: (params: any) => railsBackend.put(`/api/v1/users/${params.user.id}`, params),
  uploadAvatar: (params: any) => railsBackend.put(`/api/v1/users/${params.user.id}`, objectToFormData(params)),
  signIn: (params: any) => railsBackend.post('/api/v1/users/sign_in', params),
  sendResetPasswordInstructions: (params: any) => railsBackend.post('/api/v1/users/send_reset_password_instructions', params),
  resetPassword: (params: any) => railsBackend.post('/api/v1/users/reset_password', params),
  acceptInvitation: (params: any) => railsBackend.get('/api/v1/coach/pupils/accept_invitation', { params }),
  getPupils: (params: any) => railsBackend.get('/api/v1/users', { params: params }),
  get: (params: any) => railsBackend.get(`/api/v1/users/${params.id}`),
  register: (params: any) => railsBackend.post('/api/v1/users/register_by_invitation_token', params)
}
