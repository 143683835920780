import Vue from '@/lib/vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
// import * as Sentry from '@sentry/browser'
// import { Integrations } from '@sentry/tracing'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/stylesheets/application.sass'
import 'vue-select/src/scss/vue-select.scss'
import underscore from 'vue-underscore'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import './registerServiceWorker'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)
Vue.component('pulse-loader', PulseLoader)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(underscore)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
